import { parsePath } from '@magalu/mixer-utils';
export var parseAdsProducts = function parseAdsProducts(adsResponse) {
  return adsResponse == null ? void 0 : adsResponse.map(function (product, index) {
    if (!Object.values(product).length || !product.title) return null;
    return {
      available: true,
      brand: product.brand,
      campaignId: product.campaignId,
      category: {
        name: product.category
      },
      id: product.id,
      image: product.image,
      path: '/',
      position: index + 1,
      price: product.price,
      rating: product.rating,
      seller: {
        id: product.seller.id
      },
      subcategory: {
        name: product.subcategory.name
      },
      title: product.title,
      trackId: product.trackId,
      url: parsePath(product == null ? void 0 : product.path)
    };
  }).filter(Boolean);
};